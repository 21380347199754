import {
  useAccount,
  useBalance,
  useContractRead,
  useContractReads,
  useContractWrite,
  useNetwork,
} from "wagmi";
import { signTypedData } from "wagmi/actions";

import { Button } from "./components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./components/ui/card";
import { Input } from "./components/ui/input";
import { Label } from "./components/ui/label";
import { Separator } from "./components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs";

import * as Con from "./abis";
import { useReducer, useState } from "react";
import { formatUnits, parseEther, formatEther } from "ethers/lib/utils";
import { ethers } from "ethers";
import { cn } from "./lib/utils";
import { PenaltiesModal } from "./Penalties";
import { styled } from "styled-components";
import { TiersModal, tiers } from "./Tiers";

const Link = styled.a`
  color: #2563eb;
  text-decoration: underline;
  cursor: pointer;
`;

function AccessTabs() {
  const [stakeAmount, setStakeAmount] = useState("");
  const [unstakeAmount, setUnstakeAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [openPenalties, setOpenPenalties] = useState(false);
  const [openTiers, setOpenTiers] = useState(false);

  const { address } = useAccount();
  const { chain } = useNetwork();

  const domain = {
    name: "DuckDAO Token",
    version: "1",
    chainId: chain?.id || 1,
    verifyingContract: Con.Addresses.token as any,
  };

  const Permit = [
    { name: "owner", type: "address" },
    { name: "spender", type: "address" },
    { name: "value", type: "uint256" },
    { name: "nonce", type: "uint256" },
    { name: "deadline", type: "uint256" },
  ];

  const types = {
    Permit,
  };

  const ddBalance = useContractRead({
    ...Con.Token,
    functionName: "balanceOf",
    args: [address],
    watch: true,
  });

  const ddaBalance = useContractRead({
    ...Con.Access,
    functionName: "balanceOf",
    args: [address],
    watch: true,
  });

  const ddaData = useContractRead({
    ...Con.Access,
    functionName: "userInfo",
    args: [address],
    watch: true,
  }) as any;

  const dnData = useContractRead({
    ...Con.Token,
    functionName: "nonces",
    args: [address],
  }) as any;

  const stake = useContractWrite({
    ...Con.Access,
    functionName: "addTokensWithPermit",
  });

  const remove = useContractWrite({
    ...Con.Access,
    functionName: "removeTokens",
  });

  const pnData = useContractRead({
    ...Con.Access,
    functionName: "calculatePenalty",
    args: [
      address,
      parseEther(
        unstakeAmount === ""
          ? formatEther(ddaBalance?.data ?? "0") ?? "0"
          : unstakeAmount,
      ) ?? "0",
    ],
  });

  console.log({ddaData})

  const days = ddaData.isLoading || ddaData.isError
    ? 0
    : Math.floor((Date.now() / 1000 - parseInt(ddaData.data[1]) ?? 0) / 86400);
  const interaction = ddaData.isLoading
    ? "loading"
    : ddaData.isError ? "N/A"
    : parseInt(ddaData.data[1]) === 0 ||
        parseEther("0").eq(ddaBalance.data as any)
      ? "No Access Tokens"
      : new Date(parseInt(ddaData.data[1]) * 1000).toLocaleString() +
        ` (${days} days ago)`;

  const handleStake = async () => {
    try {
      setLoading(true);

      if (!chain || !address || !dnData || !ddBalance) {
        console.log(chain, address, dnData.data, ddBalance.data);
        throw new Error("No data");
      }

      if (dnData.isLoading || ddBalance.isLoading) {
        throw new Error("Loading");
      }

      if (dnData.error || ddBalance.error || !stake.writeAsync) {
        throw new Error(
          dnData.error?.message || ddBalance.error?.message || "Error",
        );
      }

      const amount: ethers.BigNumber =
        stakeAmount === "" ? (ddBalance.data as any) : parseEther(stakeAmount);

      const nonce = dnData.data;
      const deadline = new Date().getTime() + 1000 * 60 * 60 * 24 * 365;

      const message = {
        owner: address as any,
        spender: Con.Addresses.access as any,
        value: amount.toString(),
        nonce,
        deadline,
      } as any;

      console.log({
        domain,
        types,
        message,
        primaryType: "Permit",
      });

      const signed = await signTypedData({
        domain,
        types,
        message,
        primaryType: "Permit",
      } as any);
      const split = ethers.utils.splitSignature(signed);

      const tx = await stake.writeAsync({
        args: [amount.toString(), deadline, split.v, split.r, split.s],
      } as any);
      const hash = await tx.wait();
      console.log(hash);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleRemove = async () => {
    try {
      setLoading(true);

      if (!chain || !address || !dnData || !ddaBalance) {
        console.log(chain, address, dnData.data, ddaBalance.data);
        throw new Error("No data");
      }

      if (dnData.isLoading || ddaBalance.isLoading) {
        throw new Error("Loading");
      }

      if (dnData.error || ddaBalance.error || !remove.writeAsync) {
        throw new Error(
          dnData.error?.message || ddaBalance.error?.message || "Error",
        );
      }

      const amount =
        unstakeAmount === "" ? ddaBalance.data : parseEther(unstakeAmount);
      const tx = await remove.writeAsync({
        args: [amount.toString()],
      } as any);
      const hash = await tx.wait();
      console.log(hash);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getTierAccess = (amount?: ethers.BigNumberish) => {
    // if no amount or amount is 0
    if (!amount || amount.toString() === "0") {
      return {
        amount: "0",
        dsTier: "-",
        innerTier: "-",
      };
    }
    const tier = tiers
      .toReversed()
      .find((t) => parseEther(t.amount.toString()).lte(amount));
    return tier;
  };

  return (
    <>
      <PenaltiesModal open={openPenalties} setOpen={setOpenPenalties} />
      <TiersModal open={openTiers} setOpen={setOpenTiers} />
      <div
        id="Loader"
        className={cn(
          "fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center flex-col space-y-4 z-50",
          loading ? "" : "hidden",
        )}
      >
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-100"></div>
        <h1 className="text-white text-3xl font-semibold">
          Transaction Loading..
        </h1>
      </div>
      <Tabs defaultValue="access" className="w-[400px] mt-[6rem]">
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="access">Overview</TabsTrigger>
          <TabsTrigger value="stake">Provide</TabsTrigger>
          <TabsTrigger value="remove">Remove</TabsTrigger>
        </TabsList>
        <TabsContent value="access">
          <Card>
            <CardHeader>
              <CardTitle className="text-lg">
                DuckDAO Membership Access Platform
              </CardTitle>
              <CardDescription>
                Make sure you understand what you are doing before using the
                app.
              </CardDescription>
              <CardDescription>
                Via this portal you can get access to the DuckDAO Inner Circle
                Groups and to DuckStarter. For the exact Tier levels please see
                the DuckDAO main website.
              </CardDescription>
              <CardDescription>
                Please be aware that for early cancelling your access by
                removing your DD tokens from the smart contact a penalty will be
                applied.
              </CardDescription>
              <CardDescription>
                After adding tokens to the access platform, please join the
                InnerCircle Groups via the following links:
              </CardDescription>
              <a
                className="text-blue-400 flex flex-col cursor-pointer text-xs"
                href="https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAyMDQ5MDAyMTE3"
                target="_blank"
              >
                DuckStarter | DD Inner Circle (80+ DDA)
              </a>
              <a
                className="text-blue-400 flex flex-col cursor-pointer text-xs"
                href="https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAyMTM2MzExMzI1"
                target="_blank"
              >
                Duck Fight Club (40+ DDA)
              </a>
              <a
                className="text-blue-400 flex flex-col cursor-pointer text-xs"
                href="https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAyMDMzMjIzMTMy"
                target="_blank"
              >
                Duck Beach Club (800+ DDA)
              </a>
              <a
                className="text-blue-400 flex flex-col cursor-pointer text-xs"
                href="https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAyMDM2NzA4NTYx"
                target="_blank"
              >
                Duck Gentlemen Club (10,000+ DDA)
              </a>
              <a
                className="text-blue-400 flex flex-col cursor-pointer text-xs"
                href="https://telegram.me/collablandbot?start=VFBDI1RFTCNDT01NIy0xMDAxOTEwNzY3MjQ4"
                target="_blank"
              >
                Duck Diamond Lounge (40,000+ DDA)
              </a>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="space-y-1">
                <Label htmlFor="name">DuckDAO (DD) Balance</Label>
                <p className="text-muted-foreground">
                  {ddBalance.isLoading
                    ? "loading"
                    : formatEther(ddBalance?.data ?? "0")}
                </p>
              </div>
              <Separator />
              <div className="space-y-1">
                <Label htmlFor="name">DuckDAO Access (DDA) Balance</Label>
                <p className="text-muted-foreground">
                  {ddaBalance.isLoading
                    ? "loading"
                    : formatEther(ddaBalance?.data ?? "0")}{" "}
                  ({getTierAccess((ddaBalance?.data as any) ?? "0").dsTier} |{" "}
                  {getTierAccess((ddaBalance?.data as any) ?? "0").innerTier})
                </p>
              </div>
              <Separator />
              <div className="space-y-1">
                <Label htmlFor="name">Staking since</Label>
                <p className="text-muted-foreground">{interaction}</p>
              </div>
            </CardContent>
            <CardFooter className="flex flex-row space-x-2 justify-between">
              <div className="flex flex-row space-x-2 relative">
                <p className="text-gray-500 font-thin text-xs absolute bottom-[-20px] left-[10px]">
                  Informative Buttons
                </p>
                <Button className="" onClick={() => setOpenTiers(true)}>
                  Tiers
                </Button>
                <Button className="" onClick={() => setOpenPenalties(true)}>
                  Penalties
                </Button>
              </div>
              <Button
                onClick={() => window.open("https://duckdao.io/")}
                className="bg-yellow-500 text-white"
              >
                DuckDAO
              </Button>
            </CardFooter>
          </Card>
        </TabsContent>
        <TabsContent value="stake">
          <Card>
            <CardHeader>
              <CardTitle>DuckDAO Access (Stake)</CardTitle>
              <CardDescription>
                Stake your DD tokens to get DDA tokens.
              </CardDescription>
              <CardDescription>
                Make sure you understand what you are doing before using the
                app. If you have any questions please contact our{" "}
                <Link href="mailto:support@duckdao.io" target="_blank">
                  Support
                </Link>{" "}
                or our{" "}
                <Link href="https://t.me/duckdaolobby" target="_blank">
                  DuckDAO Lobby
                </Link>
                .
              </CardDescription>
              <CardDescription>
                DD Available:{" "}
                {ddBalance.isLoading
                  ? "loading"
                  : formatEther(ddBalance.data ?? "0")}
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="space-y-1">
                <Label htmlFor="stakeAmount">Amount</Label>
                <Input
                  id="stakeAmount"
                  type="number"
                  value={stakeAmount}
                  placeholder={formatEther(ddBalance.data ?? "0")}
                  onChange={(e) => setStakeAmount(e.target.value)}
                />
              </div>
            </CardContent>
            <CardFooter>
              <Button
                disabled={
                  loading ||
                  parseEther(stakeAmount || "0").gt(
                    ddBalance.data?.toString() ?? "0",
                  )
                }
                onClick={handleStake}
              >
                Stake (Access)
              </Button>
            </CardFooter>
          </Card>
        </TabsContent>
        <TabsContent value="remove">
          <Card>
            <CardHeader>
              <CardTitle>DuckDAO Access (Unstake)</CardTitle>
              <CardDescription>
                Unstaking will remove your DuckDAO Access Tokens (DDA) and
                return back your staked DuckDAO Tokens (DD).
              </CardDescription>
              <CardDescription>
                Make sure you understand what you are doing before using the
                app. If you have any questions please contact our{" "}
                <Link href="mailto:support@duckdao.io" target="_blank">
                  Support
                </Link>{" "}
                or our{" "}
                <Link href="https://t.me/duckdaolobby" target="_blank">
                  DuckDAO Lobby
                </Link>
                .
              </CardDescription>
              <CardDescription>
                DDA Available:{" "}
                {ddaBalance.isLoading
                  ? "loading"
                  : formatEther(ddaBalance.data ?? "0")}
              </CardDescription>
              <CardDescription>
                Penalty:{" "}
                {pnData.isLoading ? "loading" : formatEther(pnData.data ?? "0")}{" "}
                DD
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="space-y-1">
                <Label htmlFor="unstakeAmount">Amount</Label>
                <Input
                  id="unstakeAmount"
                  type="number"
                  value={unstakeAmount}
                  placeholder={formatEther(ddaBalance.data ?? "0")}
                  onChange={(e) => setUnstakeAmount(e.target.value)}
                />
              </div>
            </CardContent>
            <CardFooter className="flex flex-row space-x-2 justify-between">
              <Button
                className="bg-red-500 text-white hover:text-white hover:bg-red-800 disabled:bg-red-950"
                onClick={handleRemove}
                disabled={
                  loading ||
                  parseEther(unstakeAmount || "0").gt(
                    ddaBalance.data?.toString() ?? "0",
                  )
                }
              >
                Remove (Access)
              </Button>
            </CardFooter>
          </Card>
        </TabsContent>
      </Tabs>
    </>
  );
}

export const Access = () => {
  return (
    <div>
      <AccessTabs />
    </div>
  );
};
